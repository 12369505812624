import React from "react";
import './About.css'

const About = () => {
    return(
        <div className={"about"} id={"about"}>
            <div className={"shape1"}></div>
            <div className={"about-container"}>
                <h1>Welcome to my portfolio</h1>
                <hr/>
                <p>I'm Veronica Nadler, a full stack developer currently studying Computer Science at the Royal
                    Institute of Technology in Stockholm. With a foundation in both frontend and backend development, I
                    love transforming problems and ideas into neat, user-friendly solutions.
                </p>
                <br/>
                <p>In my portfolio, you'll find a collection of my projects. Feel free to explore, and don't hesitate to
                    reach out if you want to connect or learn more about my work.</p>
            </div>
            <div className={"shape2"}></div>
        </div>
    )
}
export default About