import React, { useRef, useState } from 'react';
import './Projects.css'
import swingmood from './Images/swingmood-picture.png'
import potbotArchitecture from './Images/potbot-diagram.png'
import potbotLogin from './Images/potbot-loginview.png'
import potbotPlants from './Images/potbot-plantview.png'
import Footer from "./Footer";

const Projects = () => {
    const [openProjects, setOpenProjects] = useState({});
    const refs = useRef({});
    const toggleDiv = (projectName) => {
        setOpenProjects((prevState) => {
            const isOpen = !prevState[projectName];
            return {
                ...prevState,
                [projectName]: isOpen,
            };
        });
    };

    return (
        <div className="projects" id={"projects"}>
            <div className={"shape3"}></div>
            <div className="content">
                <h1>Projects</h1>
                <hr/>
                <div className={"container"}>
                    <h2 className="clickable" onClick={() => toggleDiv('SwingMood')}
                        ref={(el) => (refs.current['SwingMood'] = el)}>
                        {openProjects.SwingMood ? '-' : '+'} SwingMood
                    </h2>
                    <div className={`project ${openProjects.SwingMood ? "open" : "closed"}`}>
                        <p> SwingMood is a web application developed as part of the Interaction Programming and the
                            Dynamic Web course.
                            The aim of the project was collaborative development and user experience, with a focus on
                            external API integration. The framework used for the application is Vue.
                        </p>
                        <h3>Features</h3>
                        <ul>
                            <li>A user interface</li>
                            <li>Sign up functionality and authentication.</li>
                            <li>The ability to retrieve data from two API:s</li>
                            <li>Retrieval of a single track based on emotion and weather.</li>
                            <li>Music playback</li>
                        </ul>
                        <p>
                            The application provides users with a simple interface to input their current mood from a
                            predefined list of emotions.
                            This input, combined with real-time weather data based on the user’s location, is used to
                            generate a music track tailored to the user's mood, sourced from SoundCloud.
                            The app utilizes two APIs: the SoundCloud API for music search and playback and the
                            OpenWeather API for retrieving local weather data.
                        </p>
                        <p>
                            The project emphasized user experience and interface design, aiming to create an intuitive,
                            guided interaction.
                            Users are required to register and log in to access the mood selection feature.
                            After selecting one or two emotions, the app displays the current weather and automatically
                            plays a relevant track.
                            The UI includes navigation to a home page, emotion settings, weather display, playback
                            history and a sign-out option.</p>
                        <img className={"project-picture"} src={swingmood} alt={""}/>
                        <p><a href={"https://swingmood-d93cb.web.app/"} target="_blank" rel="noopener noreferrer">SwingMood website</a></p>
                        <p><a href={"https://github.com/vrncndlr/SwingMood"} target="_blank" rel="noopener noreferrer">Project repository</a></p>

                    </div>
                </div>
                <div className={"container"}>
                    <h2 className="clickable" onClick={() => toggleDiv('PotBot')}>
                        {openProjects.PotBot ? '-' : '+'} PotBot
                    </h2>
                    <div className={`project ${openProjects.PotBot ? "open" : "closed"}`}>
                        <p>
                            PotBot was developed as part of the Project Methods course. It is an IoT-enabled smart plant
                            pot
                            designed to automate plant care tasks. PotBot is equipped with sensors, including
                            temperature, UV
                            light and soil moisture sensors, to continuously monitor environmental conditions and assess
                            the
                            plant's needs.
                            The system uses an Arduino for sensor data collection and a Raspberry Pi for data processing
                            and
                            communication. Based on the collected data, PotBot can adjust care parameters and activate a
                            water
                            pump for automatic or scheduled watering, as configured by the user through a web interface.
                            All data is stored in a Firebase Realtime Database, providing users with up-to-date
                            information on
                            their plant’s health.
                        </p>
                        <h3>Features</h3>
                        <h4>Hardware</h4>
                        <p>The PotBot system is centered around a Raspberry Pi, which serves as the primary
                            communication
                            hub.
                            Acting as a bridge between the web host and an Arduino microcontroller, the Raspberry Pi
                            forwards
                            sensor data to a Firebase Realtime Database and retrieves user settings from the database.
                            It determines when the plant requires watering based on sensor inputs and user-configured
                            settings.
                            The Arduino handles communication with the system's sensors and water pump, collecting
                            sensor
                            data and activating the pump upon receiving instructions from the Raspberry Pi.
                            The hardware components include three sensors and a water pump:</p>
                        <ul className={"project-list"}>
                            <li>Temperature Sensor: Monitors the ambient temperature around the plant, helping to ensure
                                the
                                plant is in a suitable environment for growth.
                            </li>
                            <li>UV Light Sensor: Measures the light levels reaching the plant, ensuring it receives
                                adequate
                                light for photosynthesis.
                            </li>
                            <li>Soil Moisture Sensor: Assesses the soil's water content, which is critical for
                                determining
                                when the plant needs watering.
                            </li>
                            <li>Water Pump: Delivers water to the plant, activated by data from the soil moisture sensor
                                and
                                user-defined settings.
                            </li>
                        </ul>
                        <p>Additionally, a Raspberry Pi-compatible LCD screen is included as part of the hardware. This
                            screen provides real-time data visualization and initial setup instructions for connecting
                            PotBot to the local Wi-Fi network.
                        </p>
                        <h4>Software</h4>
                        <p>The web-based application provides a user-friendly interface for managing multiple plants.
                            Firebase Authentication is used for user sign-up and sign-in, ensuring secure data storage
                            and privacy.
                            A search function retrieves data from an API, allowing users to access information on
                            different of plant species.
                            Users can view sensor data, manually water plants, and configure automatic or scheduled
                            watering settings.
                        </p>
                        <div className={'picture-block'}>
                            <img src={potbotLogin} alt={""}/>
                            <img src={potbotPlants} alt={""}/>
                        </div>
                        <h3>Architecture overview</h3>
                        <img className={"project-picture"} src={potbotArchitecture} alt={""}/>
                        <p><a href={"https://potbot-9f9ff.web.app/"} target="_blank" rel="noopener noreferrer">PotBot App</a></p>
                        <p><a href={"https://github.com/vrncndlr/PotBot"} target="_blank" rel="noopener noreferrer">Project repository</a></p>
                    </div>
                </div>
                <div className={"container"}>
                    <h2 className="clickable" onClick={() => toggleDiv('RecruitmentApp')}>
                        {openProjects.RecruitmentApp ? '-' : '+'} Recruitment App
                    </h2>
                    <div className={`project ${openProjects.RecruitmentApp ? "open" : "closed"}`}>
                        <p>This project assignment for the course Architecture and Design of Applications, simulates a
                            scenario in which a group is tasked with developing a web based recruitment application
                            for an amusement park. The system is required to implement certain features, be scalable and
                            well-documented in order to hand over to future developers. The primary goal is to have a
                            functioning prototype of the system and application which consists of a front-to-back system
                            with a user interface where an applicant can submit information that is stored in an
                            external database.
                            The project also serves as an exercise in architectural decision-making, deepening our
                            understanding of application design and structuring effective teamwork.
                        </p>
                        <h3>Features</h3>
                        <h4>Frontend</h4>
                        <p> The user interface is made using React.
                            It is structured to serve two primary users: recruiters and job applicants.
                            For applicants, the app functions as a platform to submit a job application to potential
                            employers, including personal details, areas of expertise and availability.
                            This application is general, submitted once and can be viewed in a user page.
                            For recruiters, the app operates as a database of applications, enabling them to review and
                            identify suitable candidates. As a result, the interface and functionality differ
                            significantly between recruiters and applicants.
                        </p>
                        <h4>Backend</h4>
                        <p>The backend is developed using Express, a framework for Node.js. Express provides features to handle HTTP requests and connect to databases.</p>
                        <h4>Database and hosting</h4>
                        <p>The application is hosted on Heroku, utilizing Heroku Postgres for database management.
                            Heroku Postgres is a cloud-based SQL database service and the backend server connects to it via a connection string provided by Heroku.
                            This setup enables communication between the application and the database, allowing real-time data retrieval and updates.
                        </p>
                        <p><a href={"https://github.com/vrncndlr/RecruitmentApp"} target="_blank"
                              rel="noopener noreferrer">Project repository</a></p>
                    </div>
                </div>
                {/*<div>
                    <h2 className="clickable" onClick={() => toggleDiv('Stilio')}>
                        {openProjects.Stilio ? '-' : '+'} Stilio
                    </h2>
                    <div className={`project ${openProjects.Stilio ? "open" : "closed" }`}>
                        Content
                    </div>
                </div>*/}
            </div>
            <Footer/>
        </div>
    );
}
export default Projects