import React from 'react';
import './Footer.css';

export default function Footer() {
    return (
        <div className={"footer"}>
            <div className={'footer-content'}>
                &copy; {new Date().getFullYear()} Copyright:{' '}
                <span>Veronica Nadler</span>
            </div>
        </div>
    );
}