import React, { useState } from "react";
import { Link } from "react-scroll";
import './Navigation.css'

const Navbar = () => {
    const [menuActive, setMenuActive] = useState(false);

    const handleHamburgerClick = () => {
        setMenuActive(!menuActive);
    };
    return (
        <div className="navbar">
            <div className="navbar-content">
                <div className="brand-name">
                    <h1><Link to="home">V. N</Link></h1>
                </div>
                <ul className={`nav-items ${menuActive ? 'active' : ''}`} onClick={() => setMenuActive(false)}>
                    <li><Link
                        to="home"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                        onClick={() => setMenuActive(false)}>
                        Home
                    </Link></li>
                    <li><Link
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                        onClick={() => setMenuActive(false)}>
                        About
                    </Link></li>
                    <li><Link
                        to={"projects"}
                        spy={true}
                        smooth={true}
                        offset={400}
                        duration={500}
                        onClick={() => setMenuActive(false)}>
                        Projects
                    </Link></li>
                </ul>
                <div className={`hamburger ${menuActive ? 'active' : ''}`} onClick={handleHamburgerClick}>
                    <span className={"bar"}></span>
                    <span className={"bar"}></span>
                    <span className={"bar"}></span>
                    {/*<svg xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512">
                        <path fill="#ffffff"
                              d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/>
                    </svg>*/}
                </div>
            </div>
        </div>
    );
}
export default Navbar;