import React from "react";
import './App.css';
import Navigation from './Components/Navigation'
import Home from './Components/Home'
import About from './Components/About'
import Projects from './Components/Projects'
import Footer from "./Components/Footer";

function App() {
    return (
        <div className="App">
            <Navigation/>
            <Home/>
            <About/>
            <Projects/>
        </div>
    );
}
export default App;
